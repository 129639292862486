.tabs {
   color: rgb(0 0 0 / 50%);
  font-weight: 700;
  font-variation-settings: "slnt" 0;
  display: flex;
  transition: transform 0.2s;
  cursor: pointer;
 justify-content: center;
  align-items: center;
  padding: 20px 0;
}

@media screen and (max-width : 550px) {
  .mobileview {
    display: none;
  }
  .skills, .achievestyle{
    /* font-size: xx-large !important;
    text-align: center !important; */
    display: none;
  }
  .myskills{
    display: none;
  }
  .project-button{
     display: block; /* Change display to block to move buttons onto separate lines */
    margin-left: auto; /* Center the button */
    margin-right: auto; /* Center the button */
    margin-bottom: 10px; /* Add some space between buttons */
  }
  .skillsprogress{
  margin-left: -12rem !important;
}
}


.tabs a {
  text-decoration: none !important;
  color:rgb(0 0 0 / 50%) !important;
}
body {
  overflow-x: hidden;
  font-family: "Open Sans", sans-serif;
}
.avoidlinkstyle a{
  font-weight: 700;
  font-size: larger;
  font-variation-settings: "slnt" 0;
  text-decoration: none !important;
  color:rgb(0 0 0 / 50%) !important;
}
.tabs:hover {
  transform: scale(1.1);
  /* color: #5150ed !important; */
}
.intro {
  margin: 20% 25% 0% 20%;
  font-weight: 600;
  font-size: medium;
}
.content {
  font-family: "Maven Pro", sans-serif;
  font-optical-sizing: auto;
}
.title {
  font-weight: 600;
  margin-left: 20%;
  margin-top: 15px;
}
.titleupdate {
  color: #b51ba5;
}
.desc {
  margin-left: 20%;
  margin-top: 15px;
  font-weight: 400;
}

.resume-button {
  background: #eee;
  color: #5b5b5b;
  font-weight: bold;
  border-radius: 50px;
  padding: 13px 29px;
  font-size: 14px;
  line-height: normal;
  overflow: hidden;
  transition: all 0.4s ease;
  border: 1px solid transparent;
}
.linkedin-button,.project-button {
  background: #474559;
  color: #ffffff;
  font-weight: bold;
  border-radius: 50px;
  padding: 13px 29px;
  font-size: 14px;
  line-height: normal;
  overflow: hidden;
  transition: all 0.4s ease;
  border: 1px solid transparent;
  text-decoration: none;
  margin-left: 5%;
}

@media screen and (max-width: 522px) {
  .linkedin-button {
    display: block;
    margin-top: 10px; /* Adjust margin as needed */
    margin-left: 20%;
    margin-right: 7rem;
  }
  .project-button{
    padding: 3px 0px 4px 3px !important;
    text-align: center;
  }
  .skill-icons{
    display: none;
  }
}

.expe {
  margin: 10% 0% 0% 26%;
  font-weight: 700;
}

.education {
  margin-top: 10%;
  font-weight: 700;
  margin-left: 20%;
}

.timeline-with-icons {
  border-left: 3px solid hsl(0, 0%, 0%);
  position: relative;
  list-style: none;
}

.timeline-with-icons .timeline-item {
  position: relative;
}

.timeline-with-icons .timeline-item:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline-with-icons .timeline-icon {
  position: absolute;
  left: -56px;
  background-color: hsl(217, 88.2%, 90%);
  color: hsl(217, 88.8%, 35.1%);
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.timeline {
  margin-left: 20% !important;
}
.skills {
  text-align: center;
  font-weight: bold;
  color: #ffffff;
  -webkit-text-stroke: 2px #dee2e6;
  font-size: 800%;
  text-align: right;
}
.achievestyle{
    text-align: center;
  font-weight: bold;
  color: #ffffff;
  -webkit-text-stroke: 2px #dee2e6;
  font-size: 800%;

}
.myskills {
  margin-top: -6.5%;
  position: relative;
  text-align: center;
  font-size: 150%;
  font-weight: bold;
  color: hsl(253.08deg 67.71% 49.93% / 63%);
}
.skillcontainer {
  margin-top: 5% !important;
  text-align: center;
  margin-bottom: -2%;
  justify-content: center;
}

.skillsprogress{
  margin-left: -8rem;
}

.progresscontainer {
  justify-content: space-between;
  display: flex;
  margin-left: 50%;
}
h6 {
  font-weight: 600 !important;
  color: #3c4b409c !important;
}
.pr-bar {
  margin-left: 50%;
  padding-bottom: 3%;
}

.progresscontainerII {
  justify-content: space-between;
  display: flex;
  margin-left: 50%;
}
.loadingexp {
  color: #3c4b409c !important;
  font-weight: 600;
  display: flex;
  justify-content: center;
  margin-top: 3rem;
}
.footer {
  background-color: #343a40;
  color: white;
  padding: 20px;
  display: flex;
  flex-direction: column;
  height: 25vh;
  margin-top: 40px;
  justify-content: center; /* Center vertically */
  align-items: center; /* Center horizontally */
  }
  .stick-bottom{
    position: absolute;
    bottom: 0;
    width: 100%;

  }
.sm-buttons {
  float: left;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: #ffffff;
  color: white;
  text-align: center;
  line-height: 50px;
  text-decoration: none;
  cursor: pointer;
  margin-right: 10px;
}
.socialmedia {
  display: flex;
  background-color: #fdfdfd;
  align-items: center;
  margin-left: 25%;
  margin-top: 25%;
  font-size: x-large;
}
#linkedin {
  color: #035a8a;
}
#facebook{
  color: #0064e6;
}
#instagram{
  color: #ee2a7b;;
}
.footer-content {
  align-items: center;
  display: flex;
}
.vertical-timeline::before {
    content: '';
    position: absolute;
    top: 0;
    left: 18px;
    height: 100%;
    width: 4px;
  background-color: #0064e6 !important;
}
.navbar {
  background-color: white;
  transition: background-color 0.6s linear; /* Add transition effect */
}

.scrolled {
  background-color: #f3f5ef;
}

p{
  color: white;
}
.projectcontainer {
    margin: 3% 5% 2% 4%;
    display: flex;
    flex-wrap: wrap; /* Allow items to wrap to the next line */
    justify-content: space-between; /* Distribute items evenly along the main axis */
    max-width: 100%; /* Ensure container doesn't exceed the width of its parent */
}

.projectcontainer > .cardstyle {
    width: calc(50% - 4%); /* Two boxes per row (subtracting margins) */
    margin-bottom: 2%; /* Adjust margin between boxes */
    box-sizing: border-box; /* Include padding and border in the box's total width */
}


.projectbody{
  background-color: #baa3d8;
}
.cardstyle{
  padding: 15px;  
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08); /* Updated box shadow */
      margin-top: 2rem;
    margin-left: 3rem;
    transition: transform 0.5s ease; 
}
.cardstyle:hover {
  transform: translateY(-5px); /* Add slight lift effect on hover */
}
.project-title{
  font-weight: 600;
    font-size: large;
}
.bio{
  color: rgb(0 0 0 / 50%);
    align-items: center;
    padding-top: 4rem;
}
.biointro{
  color: black !important;
  font-weight: 600;
  padding-bottom: 2rem;
}
.biocontent{
     font-weight: 600;
    color: #494545;
  padding-bottom: 2rem;
  display: flex;
}
.about{
  padding-top: 0.75rem;
}
.hireme{
margin-top: 2% !important;
}
.contactme{
  margin-left: 15%;
      width: 40%;
   cursor: pointer !important;
   border-radius: 50px !important;
   font-weight: 700;
}

